import React, { useState, useEffect } from "react";
import Breadcrumbsbanner from "../../../components/sharedComponents/breadcumbbanner/breadcumbsbanner";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { PhoneNumberUtil } from "google-libphonenumber";
import { navigate } from "gatsby";
import AdminService from "../../../components/services/admin.service";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import backn from "../../../assets/images/backIconSummary.svg";
import "react-toastify/dist/ReactToastify.css";
import { lStorage } from "../../../components/utils/storage";
import { InputText } from "primereact/inputtext";

export default function BasicForPOCRegister() {
  const [isEdit, setIsEdit] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(true);
  const PocLocation = lStorage.get("selectPOCLocation");
  const [errorPhone, setErrorPhone] = useState("");
  const [isContactValid, setIsContactValid] = useState();

  const adminServices = new AdminService();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    // setFocus,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const newData = {
      mobile_number: data?.mobile_number
        .replace(/[- ]/g, "")
        ?.replace("+91", ""),
      country_id: PocLocation.country_id,
      state_id: PocLocation.state_id,
      city_id: PocLocation.city_id,
      super_admin: 0,
    };
    setButtonDisable(true);

    await adminServices
      .addadminPOC(newData)
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            toast.success("POC added successfully");
            reset();
            setButtonDisable(false);
            setTimeout(() => {
              navigate("/admin/POC-Details");
            }, 1500);
          } else {
            setButtonDisable(false);
          }
        } else {
          if (response.data.isError === true) {
            setPhoneNumber(false);
            setErrorPhone(response.data.message[0]);
            setButtonDisable(false);
          }
        }
      })
      .catch(function (error) {
        setButtonDisable(false);
        // logOut();
      });
  };

  const backToList = () => {
    navigate("/admin/POC-Details/");
  };

  const validatePhoneNumber = (phoneNumber) => {
    let valid = false;
    const firstDigit = phoneNumber.charAt(0);
    if (!/^[6-9]/.test(firstDigit)) {
      return false;
    }
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
  };

  const clearModal = () => {
    window.location.reload();
  };

  return (
    <>
      <Breadcrumbsbanner />
      <div
        className="Basic-form-wrapper inner-pages p-4"
        style={{ marginBottom: "120px" }}
      >
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="row">
              <div className="p-2">
                <Button
                  className="btn btn-primary"
                  onClick={() => backToList()}
                >
                  <img className="img-fluid" src={backn} alt="back" />
                  Back to List
                </Button>
              </div>
              <div className="p-2 col-md-9">
                {PocLocation?.runType === "Overseas" && (
                  <>
                    <h3>Country : {PocLocation?.country}</h3>
                  </>
                )}
                {PocLocation?.runType !== "Overseas" && (
                  <>
                    <h4>
                      State: {PocLocation?.state}
                      {","} Center Name: {PocLocation?.city}
                    </h4>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div>
                <h3> Add POC </h3>
              </div>
              <div className="col-md-9">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="poc_contact_number"
                          className="form-label"
                        >
                          POC Contact No<span className="requiredColor">*</span>
                        </label>

                        {/* <Controller
                          className=""
                          control={control}
                          name="mobile_number"
                          rules={{
                            required: true,
                          }}
                          render={(props) => {
                            return (
                              <PhoneInput
                                country={"in"}
                                value={props.field.value}
                                name={props.field.name}
                                onChange={(
                                  value,
                                  country,
                                  e,
                                  formattedValue
                                ) => {
                                  // setCountryCode(country?.dialCode);
                                  props.field.onChange(formattedValue);
                                  setPhoneNumber(true);
                                  clearErrors("mobile_number");
                                  // setPhoneNumber(formattedValue?.replace(/[- ]/g, "")?.replace("+91", ""));
                                  setIsContactValid(
                                    validatePhoneNumber(
                                      formattedValue?.replace(/[- ]/g, "")?.replace("+91", "")
                                    )
                                  );
                                }}
                                inputRef={props.field.ref}
                                enableSearch={true}
                                className="phoneInput"
                              />
                              
                            );
                          }}
                        /> */}

                        {/* <span className="error_validation ml-3">
                          {errors.mobile_number?.type === "required" &&
                            "This field is required."}
                          {errors.mobile_number?.message}
                        </span>
                        <span className="error_validation ml-3">
                          {isContactValid === false &&
                            errors.contact_number?.type !== "required" &&
                            "Invalid Phone Number."}
                        </span> */}

                        <Controller
                          name="mobile_number"
                          control={control}
                          className="form-control"
                          rules={{
                            required: true,
                            pattern: {
                              value: /^[6-9]{1}[0-9]{9}$/,
                              message: "Please enter valid Phone number",
                            },
                            maxLength: 120,
                            required: true,
                          }}
                          render={({ field }) => (
                            <InputText
                              {...field}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                field.onChange(e);
                                setPhoneNumber(true);
                              }}
                            />
                          )}
                        />
                        <span className="error_validation ml-3">
                          {errors.mobile_number?.type === "required" &&
                            "This field is required."}
                          {errors.mobile_number?.message}
                          <br />
                        </span>
                        {!phoneNumber && (
                          <span className="error_validation ml-3">
                            {errorPhone}
                          </span>
                        )}
                      </div>
                      <div style={{ float: "right" }} className="form-label">
                        &nbsp;
                        <button
                          type="button"
                          className=" btn btn-secondary btn-block"
                          value="Clear"
                          onClick={() => {
                            clearModal();
                          }}
                        >
                          Clear
                        </button>
                        &nbsp;&nbsp;
                        <input
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={buttonDisable}
                          value={isEdit ? "Update" : "Submit"}
                        />
                      </div>
                    </div>
                  </div>
                  <div></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
}
